import React from "react";

const input = (props) => {
    let inputElement = null;
    let validationError = null;

    if(props.invalid && props.touched){
        validationError = <p className="error-message">Please enter a valid {props.valueType}</p>;
    }

    switch (props.elementType) {
        
        case ( 'input' ):
            inputElement = <input
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                className = "text-area col-12"
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        default: 
            inputElement = <input 
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }
    const checkInputElement = () => { 
        if( inputElement.type === 'input' ) {
            return true;
        }
        else {
            return false;
        }
    }
    return (
        <div className={ checkInputElement() ? "col-sm-4 mb-3" : "col-12" }>
            {inputElement}
            {validationError}
        </div>
        
    );
}

export default input;