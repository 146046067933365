import React , { Component } from 'react';
import SolutionsCardUI from "../UI/SolutionsCardUI"


class Solutions extends Component {
    render(){
        return (
            <section className="container solutions mb-1 pb-4" id="solution">

                <div className="solutions-title">
                    <h1 className='mt-1 pt-5'>
                        Our Solution
                    </h1>
                </div>

                <div className="container-fluid d-lg-flex flex-wrap justify-content-center">
          
                        <div className="card-outer-container col-lg-4">
                            <SolutionsCardUI
                                title = {'Adaptive Fast Charger'}
                                icon = {'assets/images/solutions/adaptive2-charger-icon.png'}
                                paragraph = {
                                        <div>
                                            <p> 
                                                An adaptive power source capable of fast charging various battery packs through novel techniques 
                                                with assured safety.
                                            </p>
                                        </div>
                                }
                            />
                        </div>

                        <div className="card-outer-container col-lg-4">
                            <SolutionsCardUI
                                title = {'Intelligent Battery Management System'} 
                                icon = {'assets/images/solutions/Ibms1-icon.png'}
                                paragraph = {
                                    <div>
                                        <p> 
                                            Implementation of advanced algorithms in monitoring the battery pack and it's performance optimization 
                                            helps us achieve a longer battery life.
                                        </p> 
                                    </div>
                                }
                            />  
                        </div>  

                        <div className="card-outer-container col-lg-4">
                                
                            <SolutionsCardUI
                                title = {'Connected Mobile Application'}
                                icon = {'assets/images/solutions/Connectd-Mobile.png'}
                                paragraph = {
                                        <div>
                                            <p> 
                                               An application designed to view real time stats of the battery pack on your phone to keep the user 
                                               updated with the performance and safety analytics.
                                            </p>
                                        </div>
                                }
                            />

                        </div>
                                
                        
                </div>

                <div className="container-fluid d-lg-flex flex-wrap justify-content-center">

                        <div className="card-outer-container col-lg-4">

                            <SolutionsCardUI
                                title = {'Innovative Communication'} 
                                icon = {'assets/images/solutions/communication-icon.png'}
                                paragraph = {
                                    <div>
                                        <p> 
                                            New methods to establish communication between all the components involved in the charging process 
                                            at a time, with a future scope of V2V interaction 
                                        </p>
                                    </div>
                                }
                            />
                        </div>
                        
                        <div className="card-outer-container col-lg-4">

                            <SolutionsCardUI
                                title = {'Proprietary Connector'} 
                                icon = {'assets/images/solutions/connector-icon.png'}
                                paragraph = {
                                    <div>
                                        <p> 
                                            An in house developed connector to suit the Indian conditions with simplicity, durability and quality 
                                            to serve the fast charging process.
                                        </p> 
                                    </div>
                                }
                            />
                                
                        </div>
                </div>  

            </section>
        )
    }
}

export default Solutions;