import React, {Component} from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactForm/ContactUs';
import Footer from './components/Footer';
import Solutions from './components/Solutions/Solutions'
import { BrowserRouter as Router} from 'react-router-dom';
import './App.css';

class App extends Component{
  render(){
    return (
    <Router>
      <Header />
      <Banner />
      <AboutUs />
      <Solutions />
      <ContactUs />
      <Footer />
    </Router>
    
    )
  }
}

export default App;
