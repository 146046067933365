import React from 'react'
 
const banner = () => {
        return(
            
            <section className="banner container-outer" id="banner"> 
                <div className='mt-5'>
                    <div className="banner-text text-center ">
                        <h2>
                            <span className='banner-tagline'>WE DRIVE ENERGY</span>
                        </h2>
                                
                        <p>
                            <span className="banner-tagline">
                                Building an intelligent ecosystem to cater range anxiety, slow recharge of battery, lack of smartness and charging technology perplexity in EVs.
                            </span>
                        </p>
                    </div>
                        
                    
                        <figure className="banner-img">
                            <img className=" banner-image" src={"assets/images/banner/banner.svg"} alt="Banner Logo" />
                        </figure>
                </div>
            </section>
            
        )
    }


export default banner;