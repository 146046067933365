import React , { Component } from 'react';
import { Link } from 'react-scroll';

class Header extends Component {

    render() {
        return (

            <header>
            <div className="container" id="home">
                <div className="header-area"> 
                    <nav className="navbar navbar-expand-md">
                        <div className="toogle">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">   
                                    <div className="toogle-icon">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>
                        </div>
                        
                        <div className="brand">
                            <Link 
                                activeClass="navbar-brand" 
                                to="banner"
                                spy={true}
                                smooth={true}
                                duration= {500}>
                                <figure className="figure">
                                    <img className="img-fluid logo" src='assets/images/Fasmho1.svg'  alt="Fasmho Energy System"/>
                                </figure>
                                <span className=" "></span>
                            </Link>
                        </div>

                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <div className="menu">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link
                                                                activeClass="active"
                                                                to="banner"
                                                                spy={true}
                                                                smooth={true}
                                                                duration= {500}
                                                            > Home </Link></li>
                                    <li className="nav-item"><Link
                                                                    activeClass="active"
                                                                    to="about-us"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    duration= {500}
                                                                > About Us </Link></li>
                                    <li className="nav-item"><Link
                                                                    activeClass="active"
                                                                    to="solution"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    duration= {500}
                                                                > Solutions </Link></li>
                                    <li className="nav-item"><Link
                                                                    activeClass="active"
                                                                    to="contact"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    duration= {500}
                                                                > Contact Us </Link></li>   
                                </ul>
                            </div>
                        </div>
                    </nav>	
                </div> 
            </div>
        </header>

        )
    }
}
export default Header;