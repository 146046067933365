import React , { Component } from "react";
import Input from "../UI/Input"
import axios from 'axios';

class ContactForm extends Component{
    constructor () {
        super();
        this.state = {
            contactDetails: { 
                name: {
                    detailType: 'name',
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Enter Your Name'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                phonenumber: {
                    detailType: 'phone number',
                    elementType: 'input',
                    elementConfig: {
                        type: 'tel',
                        placeholder: 'Enter Your Contact Number'
                    },
                    value: '',
                    validation: {
                        required: true,
                        maxLength: 10,
                        isNumber: true
                    },
                    valid: false,
                    touched: false
                }, 
                email: {
                    detailType: 'e-mail',
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        placeholder: 'Enter Your E-mail'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                message: {
                    detailType: 'message',
                    elementType: 'textarea',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Enter Your Message'
                    },
                    value: '',
                    validation: {
                        required: false
                    },
                    valid: false
                }
            },
            formIsValid: false
        };

        this.contactFormHandler = this.contactFormHandler.bind(this);
    }
    

    async contactFormHandler ( event )  {
        event.preventDefault();
        const formData = {};
        for (let formElementIdentifier in this.state.contactDetails) {
            formData[formElementIdentifier] = this.state.contactDetails[formElementIdentifier].value;
        }
        const contact = {
            contactDetails: formData
        }
       await axios.post( 'https://www.fasmho.com/api/index.php', contact)
            .then((res) => {
                if(res.data.msg === 'success'){
                    alert(`Message sent successfully , Thank You for contacting us. We shall get back to you soon.` );
                }
                else {
                    alert(`Message sent failed. Please contact us at info@fasmho.com` );
                }

                console.log(res);
            })
            .catch(() => {
                console.error();
            });
        
    }

    checkValidity(value, rules) {
        let isValid = true;
        
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isNumber) {
            isValid = !isNaN(value) && isValid
        }
        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedContactDetails = {
            ...this.state.contactDetails
        };
        const updatedFormElement = { 
            ...updatedContactDetails[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedContactDetails[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedContactDetails) {
            formIsValid = updatedContactDetails[inputIdentifier].valid && formIsValid;
        }

        this.setState({contactDetails: updatedContactDetails, formIsValid : formIsValid});
    }


    render () {
        const formElementsArray = [];
        for (let key in this.state.contactDetails) {
            formElementsArray.push({
                id: key,
                config: this.state.contactDetails[key]
            });
        }
        let form = (
            <form onSubmit={this.contactFormHandler}>

                <div className="row">
                    {formElementsArray.map(formElement => (
                            <Input 
                                id={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                errorMessage={formElement.config.error}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                valueType={formElement.config.detailType}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                    ))}
                </div>
                
                <button className="btn btn-light send-btn" disabled={!this.state.formIsValid}>Send Message</button>
            </form>
        );
        
        return (
            <div className='contact' id='contact'>
                <div className='container'>
                    <h1 className='form-title'>
                        Contact Us
                    </h1>
                    <h3 className='form-page-title'>
                        Got any questions? Send us a message.<br/>
                    </h3>
                    <div className="contact-area container">
                        {form}
                    </div>

                </div>
            </div>
        );
    }

}

export default ContactForm;