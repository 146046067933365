import React from 'react';

const SolutuionsCardUI = props => {
    return (
      <div className="solution-card text-center my-3" >

        <div className="overflow pt-3">

			<h4 className='card-title p-2'>
				{props.title}
			</h4>
			<img className="card-logo" src={props.icon} />

        </div>

        <div className="card-body text-light">

			<div className="card-text mb-5 ">
				{props.paragraph} 
			</div>

        </div>

      </div>
    );
};

export default SolutuionsCardUI;