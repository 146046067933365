import React , { Component } from 'react';
import Card  from "./Cards";
class AboutUs extends Component {
    render () {
        return (
            <div className="about-us container" id='about-us'>
                <Card/>
            </div>
            
        );
    }
}

export default AboutUs;