import React , { Component } from 'react';
import ContactForm from "./ContactForm";
class ContactUs extends Component {
    render () {
        return (
            
            <section id='contact'>
                <ContactForm/>
            </section>
                
        );
    }
}

export default ContactUs;