import React , { Component } from "react";
import Card from "../UI/CardUI";

class Cards extends Component {
    render(){
        return (
            <div className="container-fluid d-flex justify-content-center">
                <div className="row">
                    <div className="col-md-4">
                        <Card 
                            title = {'About Us'}
                            icon = {'assets/images/about-us.png'}
                            paragraph = {
                                    <div>
                                        <p> A platform for manufacturers to build their Electric Vehicles with the best of components, 
                                            which solve their core problems. From charging to smartness, we plan to cover it all.</p>
                                    </div>
                            }
                        />
                    </div>

                    <div className="col-md-4 ">
                        <Card 
                            title = {'Mission'} 
                            icon = {'assets/images/mission-logo.png'}
                            paragraph = {
                                <div>
                                    <p> To match the time of charging an Electric Vehicle to that of refueling an equivalent petrol tank 
                                        and assure safety and a longer life to the battery. </p> 
                                </div>
                            }
                        />
                        
                    </div>

                    <div className="col-md-4 ">
                        <Card 
                            title = {'Vision'} 
                            icon = {'assets/images/Final-Vision-logo.png'}
                            paragraph = {<p> To create an intelligent ecosystem of Electric Vehicles, for the wholesome adoption of electric transportation in India. </p>}
                        />
                    </div>
                </div>
            </div>
        );

    }
}

export default Cards ;