import React, { Component } from 'react';
import {a} from 'react-router-dom';

class Footer extends Component {
    getYear() {
        return new Date().getFullYear();
    }

    render(){
        return(
            <footer>
                <div className="container">
                    <div className='footer-area pt-5'>
                        <div className="row justify-content-start">
                            <div className='col-6 col-md-4 col-xl-3'>
                                <div className="footer-item mb-5">
                                    <div className='social-icons col-6'>
                                        <ul >
                                            <li className='icons'>
                                                <a href="https://www.linkedin.com/company/fasmho-energy-systems/" target='_blank'>
                                                    <i className="fab fa-linkedin fa-2x"></i>
                                                </a>
                                            </li>
                                            <li className='icons'>
                                                <a href="https://www.facebook.com/Fasmho-Energy-Systems-Pvt-Ltd-105593177824688/" target='_blank'>
                                                    <i className="fab fa-facebook fa-2x"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                            <div className='col-6 col-md-8 col-xl-9 '>
                                <div className="footer-item address mb-5 text-right">
                                    <div>
                                        <address>
                                            #14, 2nd Floor,<br/>
                                            R.T. Nagar Main Road,<br/>
                                            Opposite to R.T Nagar Post Office,<br/>
                                            560032-Bangalore, India<br/>
                                            Mob: +91 8095211612,+91 9611419024
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-container py-2">
                    <div className="copyright text-center text-white">
                        <p ><i className="far fa-copyright"></i><span> {this.getYear()} </span>Fasmho Energy Systems</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;