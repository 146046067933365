import React from 'react';

const Card = props => {
    return (
      <div className="card cards text-center mt-5 mb-4 h-75 shadow" >
			<div className="overflow pt-3">
				<h1 className='card-title pb-2'>
					{props.title}
				</h1>
				<img className="card-logo" src={props.icon} />
			</div>

			<div className="card-body text-dark">
				<div className="card-text mb-5 text-secondary text-justify">
					{props.paragraph} 
				</div>
			</div>
      </div>
    );
};

export default Card;